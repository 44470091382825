<template>
    <section class="select-matrix-bodegas">
        <navbar-admin icon="icon-shopping"  />
        <div class="row my-3 titulo-divisor">
            <!-- regresar -->
            <div class="col-auto my-auto">
                <p class="f-600 f-14 ucfirst"> 
                    <i class="icon-left-open mr-1 cr-pointer" @click="goBack" />
                    Agregar bodega
                </p>
            </div>
            <div class="col my-auto pl-0">
                <hr class="my-0 bg-white" />
            </div>
            <div class="col-auto my-auto">
                <!-- agregar bodega -->
                <el-popover v-model="visibleAddBodega" placement="bottom-end" width="360" trigger="manual" class="px-0">
                    <div class="row mx-0 justify-content-center f-600 text-general">
                        Agregar bodega
                    </div>
                    <hr />
                    <ValidationObserver ref="validatorBodega">
                        <div class="row mx-0 justify-content-center my-2">
                            <div class="col-11 my-2">
                                <ValidationProvider v-slot="{ errors }" name="bodega" rules="required">
                                    <p class="input-label-top">Seleccionar bodega</p>
                                    <el-select v-model="modelBodega.id_bodega" multiple clearable placeholder="Seleccionar bodega" size="small" class="w-100">
                                        <el-option v-for="bodega in bodegas" :key="bodega.id" :label="bodega.nombre" :value="bodega.id" />
                                    </el-select>
                                    <vee-error :text="errors[0]" />
                                </ValidationProvider>
                            </div>
                        </div>
                    </ValidationObserver>
                    <hr />
                    <div class="text-right m-0">
                        <button class="btn btn-cerrar f-12" @click="visibleAddBodega = !visibleAddBodega">Cerrar</button>
                        <button class="btn btn-crear f-12 ml-2" @click="addBodega">Guardar</button>
                    </div>
                    <el-tooltip slot="reference" content="Agregar bodega" effect="light" placement="top" visible-arrow>
                        <i class="icon-plus-circle cr-pointer f-30" @click="openAddBodega" />
                    </el-tooltip>
                </el-popover>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-6">
                <div class="shadow29 br-5 py-3">
                    <p class="text-general text-center f-600 f-14 border-bottom-g pb-3">Características del material</p>
                    <div class="row justify-content-center my-2">
                        <div class="col-5 text-right">
                            <p class="text-general f-600 f-14">Nombre material</p>
                        </div>
                        <div class="col-6">
                            <p class="f-12">{{ materiales.material }}</p>
                        </div>
                    </div>
                    <div class="row justify-content-center my-2">
                        <div class="col-5 text-right">
                            <p class="text-general f-600 f-14">Cantidad</p>
                        </div>
                        <div class="col-6">
                            <p class="f-12">{{ materiales.cantidad }}</p>
                        </div>
                    </div>
                    <div v-for="(proveedor, index) in materiales.proveedores" :key="proveedor.id" class="row justify-content-center my-2">
                        <div class="col-5 my-auto text-right">
                            <p class="text-general f-600 f-14">{{ proveedor.proveedor }}</p>
                        </div>
                        <div class="col-6">
                            <div class="d-middle">
                                <el-input v-model="proveedor.cantidad" size="small" placeholder="cantidad" class="w-50" @input="limitProviderItems(index, $event)"/>
                                <div class="text-general f-12 ml-3 text-center">
                                    <p class="border-bottom-g">Valor Uni</p>
                                    <p>{{ formatoMoneda(proveedor.valor_uni) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="bg-f6 py-2">
                        <div v-for="(bodega, index) in materiales.bodegas" :key="bodega.id" class="row justify-content-center my-2">
                            <div class="col-5 my-auto text-right">
                                <p class="text-general f-600 f-14">{{ bodega.nombre }}</p>
                            </div>
                            <div class="col-6">
                                <div class="d-middle">
                                    <el-input v-model="bodega.orden" size="small" placeholder="cantidad" class="w-50" @input="limitItemsWarehouse(bodega.cantidad, index, $event)"/>
                                    <div class="text-general f-12 ml-3 text-center">
                                        <p class="border-bottom-g">Cant. disponible</p>
                                        <p>{{bodega.cantidad}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                     <div class="row justify-content-center mt-3">
                        <button class="btn btn-general f-12 px-4" @click="acceptBodegas">Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    data() {
        return {
            visibleAddBodega: false,
            modelBodega:{
                id_bodega: ''
            },
            proveedor1: '3000',
            bodega1: '',
            bodegasOrden: [],
            materiales: [],
            valores: [],
            materialCount: 0
        }
    },
    computed: {
        ...mapGetters({
            bodegas: 'compras/ordenes_compra/bodegas',
            valores_material: 'compras/ordenes_compra/valores_material',
            cantidades_material: 'compras/ordenes_compra/cantidades_material',
        })
    },
    methods: {
        ...mapActions({
            obtenerBodegas: 'compras/ordenes_compra/Action_get_bodegas',
            obtenerValoresMaterial: 'compras/ordenes_compra/Action_get_valores_material',
            obtenerCantidadEnBodegas: 'compras/ordenes_compra/Action_get_cantidad',
        }),

        openAddBodega(){
            this.visibleAddBodega = !this.visibleAddBodega
        },

        async addBodega(){
            let valid = await this.$refs.validatorBodega.validate()
            if(valid){
                const warehouse = this.bodegas.find(bodega => bodega.id == this.modelBodega.id_bodega)


                await this.obtenerCantidadEnBodegas({materiales: [this.materiales.id], bodegas: [warehouse.id]})
                warehouse.cantidad = this.cantidades_material.find(cantidad => cantidad.bodegas[0].id == warehouse.id).bodegas[0].cantidad
                warehouse.orden = 0

                //Find warehouse to replace it or push a new
                const findWarehouse = this.bodegasOrden.find(bodega => bodega.id === warehouse.id)

                if(findWarehouse){
                    const index = this.bodegasOrden.indexOf(warehouse)
                    this.bodegasOrden[index] = warehouse
                } else {
                    this.bodegasOrden.push(warehouse)
                }

                this.materiales.bodegas.push(warehouse)

                const materialLocal = JSON.parse(localStorage.getItem('materiales'))
                const indexMaterial = materialLocal.indexOf(materialLocal.find(material => this.materiales.id == material.id))

                materialLocal[indexMaterial] = this.materiales

                localStorage.setItem('bodegas', JSON.stringify(this.bodegasOrden))
                localStorage.setItem('materiales', JSON.stringify(materialLocal))

                // agregar proveedor
                this.visibleAddBodega = !this.visibleAddBodega
            }
        },

        acceptBodegas(){
            let auxMateriales = JSON.parse(localStorage.getItem('materiales'))

            auxMateriales.forEach(
                (material, index) => {
                    if(material.id == this.materiales.id){
                        auxMateriales[index] = this.materiales
                    }
                }
            )

            localStorage.setItem('materiales', JSON.stringify(auxMateriales))

            this.$router.push({ name: 'compras.crear.matriz.proveedores' })
        },

        limitProviderItems(index, e){
            if(!isNaN(e)){
                const count = parseInt(e) + this.materialCount

                if(count > this.materiales.cantidad){
                    this.materiales.proveedores[index].cantidad = 0
                    this.$notify({title:'Error de cantidad', message:'No puedes ingresar una cantidad mayor a la cantidad necesaria del material', type: 'info'})
                }
                else {
                    this.materialCount = count
                }

                if(e > this.materiales.cantidad){
                    this.materiales.proveedores[index].cantidad = 0
                    this.$notify({title:'Error de cantidad', message:'No puedes ingresar una cantidad mayor a la cantidad necesaria del material', type: 'info'})
                }
            } else {
                this.materiales.proveedores[index].cantidad = 0
            }
        },

        limitItemsWarehouse(cantidad, index, e){
            if(!isNaN(e)){
                const count = parseInt(e) + this.materialCount

                if(count > this.materiales.cantidad){
                    this.materiales.bodegas[index].cantidad = 0
                    this.$notify({title:'Error de cantidad', message:'No puedes ingresar una cantidad mayor a la cantidad necesaria del material', type: 'info'})
                }
                else {
                    this.materialCount = count
                }

                if(e > cantidad){
                    this.materiales.bodegas[index].orden = 0
                    this.$notify({title:'Error de cantidad', message:'No puedes ingresar una cantidad mayor a la capacidad de la bodega', type: 'info'})
                }
                if(e > this.materiales.cantidad){
                    this.materiales.bodegas[index].orden = 0
                    this.$notify({title:'Error de cantidad', message:'No puedes ingresar una cantidad mayor a la cantidad necesaria del material', type: 'info'})
                }
            } else {
                this.materiales.bodegas[index].orden = 0
            }
           
        },

        goBack(){
            this.$router.push({ name: 'compras.crear.matriz.proveedores' })
        },
    },
    created(){
        if(!this.$route.params.id){
            this.$notify({title: 'Procedimiento incorrecto', type:'error'})
            this.$router.push({name: 'compras.crear.matriz.proveedores'})
        }
        const materialLocal = JSON.parse(localStorage.getItem('materiales'))
        console.log(materialLocal)
        let bodegas = JSON.parse(localStorage.getItem('bodegas'))

        this.materiales = materialLocal.find(material => material.id == this.$route.params.id)
    },
    async mounted(){
        await this.obtenerBodegas()
        await this.obtenerValoresMaterial({materiales: [this.$route.params.id], proveedores: this.materiales.proveedores.map(p => p.id)})

        this.materiales.proveedores.forEach(
            (proveedor) => {
                this.valores_material[0].proveedor.forEach(
                    (m_proveedor) => {
                        if(m_proveedor.id === proveedor.id){
                            proveedor.valor = m_proveedor.valor ?? 0
                        }
                    }
                )
            }
        )
    }

}
</script>

<style>

</style>